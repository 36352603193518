//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TabFooter from '~/components/FinanceApplication/Tabs/TabFooter'
import {
  ERROR_MESSAGE_FOR_EMAIL,
  ERROR_MESSAGE_FOR_INVALID_EMAIL,
  ERROR_MESSAGE_FOR_MOBILE,
  ERROR_MESSAGE_FOR_INVALID_MOBILE,
  ERROR_MESSAGE_FOR_LAST_NAME,
  ERROR_MESSAGE_FOR_STATE,
  ERROR_MESSAGE_FOR_FIRST_NAME,
  REGEX_FOR_AUS_MOBILES,
  ERROR_MESSAGE_FOR_VISA_OTHER,
  REGEX_FOR_AUS_PHONE,
  ERROR_MESSAGE_FOR_STATUS,
  ERROR_MESSAGE_FOR_DRIVER_LICENCE_STATE,
  ERROR_MESSAGE_FOR_DRIVER_LICENCE_TYPE,
  ERROR_MESSAGE_FOR_ID_TYPE,
  ERROR_MESSAGE_FOR_DEPENDANTS,
  ERROR_MESSAGE_FOR_DATE_OF_BIRTH,
  ERROR_MESSAGE_FOR_LICENCE_DATE_OF_EXPIRY,
  ERROR_MESSAGE_FOR_GENDER,
  ERROR_MESSAGE_FOR_LIVING_SITUATION,
  ERROR_MESSAGE_FOR_DRIVER_LICENCE,
  ERROR_MESSAGE_FOR_PASSPORT_DATE_OF_EXPIRY,
  ERROR_MESSAGE_FOR_PASSPORT_NUMBER,
  ERROR_MESSAGE_FOR_18_YEARS_AGE,
  ERROR_MESSAGE_FOR_DATE_LESS_THAN_10_YEARS_CURR_YEAR,
  ERROR_MESSAGE_FOR_SHAREHOLDING_PERCENTAGE,
  ERROR_MESSAGE_FOR_DIRECTORSHIP_START_DATE,
  ERROR_MESSAGE_FOR_MAX_20_CHARS,
} from '~/constants'
import {
  APPLICANT_PREFIXES_OPTIONS,
  APPLICANT_STATE_OPTIONS,
  RESIDENCY_STATUS_OPTIONS,
  APPLICANT_RELATIONSHIP_OPTIONS,
  APPLICANT_DRIVER_LICENCE_OPTIONS,
  RESIDENCY_STATUS_VISA_OTHER_VALUE,
  LIVING_SITUATIONS_MAY_PAY_RENT_OR_BOARD,
  DEPENDANTS_LIST,
  APPLICANT_RELATIONSHIP_STATUS_SINGLE,
  LABEL_FOR_BASIC_DETAILS,
  LABEL_BUSINESS_DETAILS,
  LABEL_FOR_SHAREHOLDING_PERCENTAGE,
  LABEL_FOR_DIRECTORSHIP_START_DATE,
  FINANCE_APPLICATION_COMMERCIAL_TAB_CONTACTS_KEY,
  SUBTAB_PRIMARY_ADDRESS_KEY,
  FINANCE_APPLICATION_COMMERCIAL_TAB_BUSINESS_DETAILS_KEY,
  LABEL_BUSINESS_STRUCTURE_PARTNERSHIP,
  LABEL_BUSINESS_STRUCTURE_TRUST,
  LABEL_BUSINESS_TRUSTEE_TYPE_COMPANY,
  LABEL_BUSINESS_STRUCTURE_COMPANY,
  SUBTAB_BUSINESS_ACCOUNTANT_KEY,
  SUBTAB_PRIMARY_PERSONAL_KEY,
  SUBTAB_PRIMARY_EMPLOYMENT_KEY,
  LABEL_VISA_SUBCLASS,
} from '~/constants/applications'
import { changeRoute } from '~/helpers/changeRoute'
import {
  isdateOfBirthGTE18YearsAndLessThan110Years,
  isdateLessThan10Years,
  validateDistinctMobileNumber,
} from '~/helpers/validators'
import {
  ID_TYPE_DRIVER_LICENCE,
  ID_TYPE_PASSPORT,
  LABEL_TYPE_DRIVERS_LICENCE,
} from '~/constants/user'
import NotificationBar from '~/components/UtilComponents/NotificationBar.vue'
import { getTypeOfId } from '~/store/applications/methods'
import { cloneDeep } from 'lodash/cloneDeep'
import { setResidencyStatus } from '~/helpers/application'
import { toUTCWithoutOffset } from '~/utils/date'
export default {
  components: {
    TabFooter,
    NotificationBar,
  },
  props: {
    applicationDetails: {
      type: Object,
      default: null,
    },
    vehicleDetails: {
      type: Object,
      default: null,
    },
    userDetails: {
      type: Object,
      default: null,
    },
    secondaryApplicant: {
      type: Object,
      default: null,
    },
    accountDetails: {
      type: Array,
      default: null,
    },
    vehicleRequestGroup: {
      type: Object,
      default: null,
    },
    activeMenu: {
      type: String,
      default: '1',
    },
    isConsumer: {
      type: Boolean,
      default: false,
    },
    sharedData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      SUBTAB_PRIMARY_PERSONAL_KEY,
      cloneDeep,
      isEditMode: false,
      primaryApplicantData: null,
      LABEL_BUSINESS_DETAILS,
      LABEL_FOR_SHAREHOLDING_PERCENTAGE,
      LABEL_FOR_DIRECTORSHIP_START_DATE,
      ERROR_MESSAGE_FOR_EMAIL,
      ERROR_MESSAGE_FOR_INVALID_EMAIL,
      ERROR_MESSAGE_FOR_MOBILE,
      ERROR_MESSAGE_FOR_INVALID_MOBILE,
      ERROR_MESSAGE_FOR_LAST_NAME,
      ERROR_MESSAGE_FOR_STATE,
      ERROR_MESSAGE_FOR_FIRST_NAME,
      REGEX_FOR_AUS_MOBILES,
      ERROR_MESSAGE_FOR_VISA_OTHER,
      REGEX_FOR_AUS_PHONE,
      ERROR_MESSAGE_FOR_STATUS,
      ERROR_MESSAGE_FOR_DRIVER_LICENCE_STATE,
      ERROR_MESSAGE_FOR_DRIVER_LICENCE_TYPE,
      ERROR_MESSAGE_FOR_ID_TYPE,
      ERROR_MESSAGE_FOR_DEPENDANTS,
      ERROR_MESSAGE_FOR_DATE_OF_BIRTH,
      ERROR_MESSAGE_FOR_LICENCE_DATE_OF_EXPIRY,
      ERROR_MESSAGE_FOR_GENDER,
      ERROR_MESSAGE_FOR_LIVING_SITUATION,
      ERROR_MESSAGE_FOR_DRIVER_LICENCE,
      ERROR_MESSAGE_FOR_PASSPORT_DATE_OF_EXPIRY,
      ERROR_MESSAGE_FOR_PASSPORT_NUMBER,
      ERROR_MESSAGE_FOR_18_YEARS_AGE,
      ERROR_MESSAGE_FOR_DATE_LESS_THAN_10_YEARS_CURR_YEAR,
      LABEL_VISA_SUBCLASS,
      APPLICANT_PREFIXES_OPTIONS,
      APPLICANT_STATE_OPTIONS,
      RESIDENCY_STATUS_OPTIONS,
      APPLICANT_RELATIONSHIP_OPTIONS,
      APPLICANT_DRIVER_LICENCE_OPTIONS,
      RESIDENCY_STATUS_VISA_OTHER_VALUE,
      LIVING_SITUATIONS_MAY_PAY_RENT_OR_BOARD,
      DEPENDANTS_LIST,
      APPLICANT_RELATIONSHIP_STATUS_SINGLE,
      LABEL_FOR_BASIC_DETAILS,
      ID_TYPE_DRIVER_LICENCE,
      LABEL_TYPE_DRIVERS_LICENCE,
      ID_TYPE_PASSPORT,
      ERROR_MESSAGE_FOR_SHAREHOLDING_PERCENTAGE,
      ERROR_MESSAGE_FOR_DIRECTORSHIP_START_DATE,
      LABEL_BUSINESS_STRUCTURE_PARTNERSHIP,
      LABEL_BUSINESS_STRUCTURE_TRUST,
      LABEL_BUSINESS_TRUSTEE_TYPE_COMPANY,
      LABEL_BUSINESS_STRUCTURE_COMPANY,
      ERROR_MESSAGE_FOR_MAX_20_CHARS,
      SUBTAB_PRIMARY_EMPLOYMENT_KEY,
      rulesPrimary: {
        residencyStatus: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_STATUS,
            trigger: ['blur', 'change'],
          },
        ],

        relationshipStatus: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_STATUS,
            trigger: ['blur', 'change'],
          },
        ],
        'userData.driverLicenceState': [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_DRIVER_LICENCE_STATE,
            trigger: ['blur', 'change'],
          },
        ],
        'userData.driverLicenceType': [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_DRIVER_LICENCE_TYPE,
            trigger: ['blur', 'change'],
          },
        ],
        'userData.dateOfBirth': [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_DATE_OF_BIRTH,
            trigger: ['blur', 'change'],
          },
          {
            validator: (_rule, value, callback) => {
              if (
                !value ||
                (value && !isdateOfBirthGTE18YearsAndLessThan110Years(value))
              ) {
                callback(new Error(ERROR_MESSAGE_FOR_18_YEARS_AGE))
              } else {
                callback()
              }
            },
            message: ERROR_MESSAGE_FOR_18_YEARS_AGE,
            trigger: 'change',
          },
        ],
        'userData.driverLicenceExpiry': [
          {
            validator: (_rule, value, callback) => {
              if (value && !isdateLessThan10Years(value)) {
                callback(
                  new Error(ERROR_MESSAGE_FOR_DATE_LESS_THAN_10_YEARS_CURR_YEAR)
                )
              } else {
                callback()
              }
            },
            message: ERROR_MESSAGE_FOR_DATE_LESS_THAN_10_YEARS_CURR_YEAR,
            trigger: 'change',
          },
        ],
        'userData.idType': [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_ID_TYPE,
            trigger: ['blur', 'change'],
          },
        ],
        numberOfDependants: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_DEPENDANTS,
            trigger: ['blur', 'change'],
          },
        ],
        visaOther: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_VISA_OTHER,
            trigger: ['blur', 'change'],
          },
        ],
        'userData.firstName': [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_FIRST_NAME,
            trigger: ['blur', 'change'],
          },
        ],
        'userData.driverLicenceCardNumber': [
          {
            max: 20,
            message: ERROR_MESSAGE_FOR_MAX_20_CHARS,
            trigger: ['blur', 'change'],
          },
        ],
        'userData.gender': [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_GENDER,
            trigger: ['blur', 'change'],
          },
        ],
        // To fulfill the purpose of https://carclarity.atlassian.net/browse/WEB-4620
        // 'userData.mailingAddress.state': [
        //   {
        //     required: true,
        //     message: ERROR_MESSAGE_FOR_STATE,
        //     trigger: ['blur', 'change'],
        //   },
        // ],
        'userData.lastName': [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_LAST_NAME,
            trigger: ['blur', 'change'],
          },
        ],
        'userData.homePhone': [
          {
            message: ERROR_MESSAGE_FOR_MOBILE,
            trigger: 'change',
          },
          {
            pattern: REGEX_FOR_AUS_PHONE,
            message: ERROR_MESSAGE_FOR_INVALID_MOBILE,
            trigger: 'change',
          },
        ],
        'userData.driverLicence': [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_DRIVER_LICENCE,
            trigger: 'blur',
          },
        ],
        'userData.email': [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_EMAIL,
            trigger: ['blur', 'change'],
          },
          {
            type: 'email',
            message: ERROR_MESSAGE_FOR_INVALID_EMAIL,
            trigger: ['blur', 'change'],
          },
        ],
        'userData.passportNumber': [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_PASSPORT_NUMBER,
            trigger: ['blur', 'change'],
          },
        ],
        'userData.passportDateOfExpiry': [],
        shareholdingPercentage: [
          {
            required: false,
            message: ERROR_MESSAGE_FOR_SHAREHOLDING_PERCENTAGE,
            trigger: ['blur', 'change'],
          },
        ],
        directorshipStartDate: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_DIRECTORSHIP_START_DATE,
            trigger: ['blur', 'change'],
          },
        ],
      },
      dateOfBirthSort: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      saveBtnLoading: false,
      saveAndNextBtnLoading: false,
      isPrimary: false,
      secondaryApplicantIndex: 0,
    }
  },
  computed: {
    existingMobileNumbers() {
      return [
        ...this.applicationDetails.otherApplicants.map(
          (otherApplicant) => otherApplicant?.applicant?.mobile
        ),
        this.sharedData?.primaryApplicantData?.primaryApplicant?.mobile,
      ].filter(Boolean)
    },
    isCompany() {
      return (
        this.applicationDetails?.businessData?.businessStructure ===
        LABEL_BUSINESS_STRUCTURE_COMPANY
      )
    },
    showBusinessDetailsSection() {
      return (
        !this.isConsumer && this.applicationDetails?.otherApplicants?.length > 0
      )
    },
    isIdTypeLicence() {
      return (
        this.primaryApplicantData?.userData?.idType === ID_TYPE_DRIVER_LICENCE
      )
    },
    hasDirector() {
      const { businessData } = this.applicationDetails
      return (
        businessData?.businessStructure === LABEL_BUSINESS_STRUCTURE_COMPANY &&
        businessData?.noOfDirectors > 0
      )
    },
    hasPartners() {
      const { businessData } = this.applicationDetails
      return (
        businessData?.businessStructure ===
          LABEL_BUSINESS_STRUCTURE_PARTNERSHIP && businessData?.noOfPartners > 0
      )
    },
    hasTrustCompany() {
      const { businessData } = this.applicationDetails
      return (
        businessData?.businessStructure === LABEL_BUSINESS_STRUCTURE_TRUST &&
        businessData?.trusteeType === LABEL_BUSINESS_TRUSTEE_TYPE_COMPANY
      )
    },
    valueOfDirectors() {
      const { businessData } = this.applicationDetails
      if (this.hasPartners) {
        return businessData?.noOfPartners
      } else if (this.hasTrustCompany) {
        return businessData?.noOfTrusteeDirectors
      } else {
        return businessData?.noOfDirectors
      }
    },
    lengthOfContacts() {
      const noOfPrimaryApplicant = this.applicationDetails?.primaryApplicantData
        ?.primaryApplicant
        ? 1
        : null
      const noOfSecondaryApplicant =
        this.applicationDetails?.otherApplicants.length
      if (noOfPrimaryApplicant && noOfSecondaryApplicant === 0) {
        return noOfPrimaryApplicant
      }
      return noOfPrimaryApplicant + noOfSecondaryApplicant
    },
    hasNoOfDirectorsMatched() {
      return this.valueOfDirectors === this.lengthOfContacts
    },
    hasWarningNoOf() {
      return Math.abs(this.valueOfDirectors - this.lengthOfContacts)
    },
    warningMessage() {
      if (this.valueOfDirectors < this.lengthOfContacts) {
        return `No. of directors does not match number of contacts. Please select  ${this.hasWarningNoOf} more directors`
      }
      return `No. of directors does not match number of contacts. Please add  ${this.hasWarningNoOf} more contacts`
    },
    mobileNumberRules() {
      return [
        {
          required: true,
          message: ERROR_MESSAGE_FOR_MOBILE,
          trigger: 'change',
        },
        {
          pattern: REGEX_FOR_AUS_MOBILES,
          validator: (_rule, value, callback) =>
            validateDistinctMobileNumber(
              _rule,
              value,
              callback,
              this.isPrimary
                ? this.applicationDetails.primaryApplicantData?.primaryApplicant
                    ?.mobile
                : this.sharedData.otherApplicants[this.secondaryApplicantIndex]
                    ?.applicant?.mobile,
              this.existingMobileNumbers
            ),
          trigger: 'change',
        },
      ]
    },
  },
  watch: {
    '$route.query'() {
      if (
        this.$route.query.maintab ===
        FINANCE_APPLICATION_COMMERCIAL_TAB_CONTACTS_KEY
      ) {
        this.updateData()
        setResidencyStatus(this.primaryApplicantData)
      }
    },
  },
  mounted() {
    this.$nuxt.$on('update-component-data', () => {
      this.updateData()
    })
    this.updateData()
    setResidencyStatus(this.primaryApplicantData)
  },
  methods: {
    updateData() {
      const { subtab: subTab = '' } = this.$route.query
      this.isPrimary = subTab?.indexOf('primary_') !== -1 || !subTab
      this.secondaryApplicantIndex = this.primary
        ? 0
        : parseInt(subTab?.split('_')[1]) - 2 || 0
      this.primaryApplicantData = {
        ...(this.isPrimary && { userData: _.cloneDeep(this.userDetails) }),
        ...(this.isPrimary && this.applicationDetails.primaryApplicantData),
        ...(!this.isPrimary &&
          this.sharedData.otherApplicants[this.secondaryApplicantIndex]),
        ...(!this.isPrimary && {
          userData: _.cloneDeep(
            this.sharedData.otherApplicants[this.secondaryApplicantIndex]
              ?.applicant
          ),
          idType:
            this.sharedData.otherApplicants[this.secondaryApplicantIndex]
              ?.applicant?.idType ||
            getTypeOfId(
              this.sharedData.otherApplicants[this.secondaryApplicantIndex]
                ?.applicant?.idTypes
            ),
        }),
        ...this.applicationDetails.businessDetails,
        directorshipStartDate: this.getdate(
          this.isPrimary,
          this.secondaryApplicantIndex
        ),
      }
    },
    getdate(isPrimary, secondaryApplicantIndex) {
      if (isPrimary) {
        if (
          !this.applicationDetails?.primaryApplicantData?.directorshipStartDate
        ) {
          return new Date(this.sharedData?.businessData?.aBNEffectiveFrom)
        } else {
          return this.applicationDetails?.primaryApplicantData
            ?.directorshipStartDate
        }
      } else {
        if (
          !this.sharedData.otherApplicants[secondaryApplicantIndex]
            ?.directorshipStartDate
        ) {
          return new Date(this.sharedData?.businessData?.aBNEffectiveFrom)
        } else {
          return this.sharedData.otherApplicants[secondaryApplicantIndex]
            ?.directorshipStartDate
        }
      }
    },
    handleShareholdingPercentage(event) {
      if (isNaN(event)) {
        return
      }
      if (event < 0 || event > 100) {
        return
      }
      this.$nextTick(() => {
        this.$set(this.primaryApplicantData, 'shareholdingPercentage', event)
      })
    },
    async submitForm(event) {
      const { gotoNext: goNext = false } = event ?? {}
      if (
        this.primaryApplicantData.relationshipStatus ===
        this.APPLICANT_RELATIONSHIP_STATUS_SINGLE
      ) {
        this.primaryApplicantData.partnerIncomeYearly = 0
        this.primaryApplicantData.partnerNetIncome = 0
      }
      const { id, applicationId } = this.$route.params
      let isValid = false
      this.$refs.primaryApplicantData.validate((result) => {
        return (isValid = result)
      })
      if (!isValid) {
        return
      }
      this.saveBtnLoading = !goNext
      this.saveAndNextBtnLoading = !!goNext
      console.log(this.primaryApplicantData)
      if (this.primaryApplicantData.userData.dateOfBirth) {
        this.primaryApplicantData.userData.dateOfBirth = toUTCWithoutOffset(
          new Date(this.primaryApplicantData.userData.dateOfBirth)
        )
      }
      if (this.primaryApplicantData.userData.driverLicenceExpiry) {
        this.primaryApplicantData.userData.driverLicenceExpiry =
          toUTCWithoutOffset(
            new Date(this.primaryApplicantData.userData.driverLicenceExpiry)
          )
      }
      await this.$store.dispatch('applications/updateApplication', {
        primaryApplicant: this.primaryApplicantData,
        id,
        applicationId,
        isSecondary: !this.isPrimary,
        index: this.secondaryApplicantIndex,
      })
      //UpdateLinks
      const { maintab } = this.$route.query
      if (maintab === FINANCE_APPLICATION_COMMERCIAL_TAB_CONTACTS_KEY) {
        await this.$store.dispatch('applications/generateNewAppLink', {
          id,
          applicationId,
        })
      }
      //\UpdateLinks
      this.saveBtnLoading = false
      this.saveAndNextBtnLoading = false

      if (goNext) {
        this.$nuxt.$emit('changeContactsTab', {
          tab: FINANCE_APPLICATION_COMMERCIAL_TAB_CONTACTS_KEY,
          subTab: `${
            this.isPrimary
              ? 'primary_' + SUBTAB_PRIMARY_ADDRESS_KEY
              : 'secondary_' +
                this.secondaryApplicantIndex +
                '_' +
                SUBTAB_PRIMARY_ADDRESS_KEY
          }`,
          isPrimary: this.isPrimary,
          index: this.secondaryApplicantIndex,
        })
      }
    },
    async goBack() {
      if (this.isPrimary) {
        this.$nuxt.$emit(
          'updateBusinessDetailsTabComponent',
          `${FINANCE_APPLICATION_COMMERCIAL_TAB_BUSINESS_DETAILS_KEY}|${SUBTAB_BUSINESS_ACCOUNTANT_KEY}`
        )
        this.$nuxt.$emit('changeTab', {
          tab: FINANCE_APPLICATION_COMMERCIAL_TAB_BUSINESS_DETAILS_KEY,
          subTab: SUBTAB_BUSINESS_ACCOUNTANT_KEY,
        })
      } else {
        await changeRoute({
          route: this.$route,
          router: this.$router,
          query: {
            ...this.$route.query,
            subTab: `${
              this.secondaryApplicantIndex === 0
                ? 'primary_' + SUBTAB_PRIMARY_EMPLOYMENT_KEY
                : 'secondary_' +
                  this.secondaryApplicantIndex +
                  '_' +
                  SUBTAB_PRIMARY_EMPLOYMENT_KEY
            }`,
            isPrimary: this.secondaryApplicantIndex > 0 ? false : true,
            index:
              this.secondaryApplicantIndex > 0
                ? this.secondaryApplicantIndex - 1
                : 0,
          },
        })
        this.$nuxt.$emit('changeContactsTab', {
          tab: FINANCE_APPLICATION_COMMERCIAL_TAB_CONTACTS_KEY,
          subTab: `${
            this.secondaryApplicantIndex === 0
              ? 'primary_' + SUBTAB_PRIMARY_EMPLOYMENT_KEY
              : 'secondary_' +
                this.secondaryApplicantIndex +
                '_' +
                SUBTAB_PRIMARY_EMPLOYMENT_KEY
          }`,
          isPrimary: this.secondaryApplicantIndex > 0 ? false : true,
          index:
            this.secondaryApplicantIndex > 0
              ? this.secondaryApplicantIndex - 1
              : 0,
        })
      }
    },
    handleDependantChange(event) {
      const data = new Array(event).fill(0).map((r) => ({ age: 0 }))
      this.$nextTick(() => {
        this.$set(this.primaryApplicantData, 'dependants', data)
        this.$set(this.primaryApplicantData, 'numberOfDependants', event)
      })
    },
  },
}
