import { USER_SESSION_DATA_KEY } from '~/constants'
import { COMMON_LOGIN_ROUTE } from '~/helpers/portal'

const ERROR_MESSAGE_IF_THROW_BY_NUXT_AUTH =
  'Both token and refresh token have expired. Your request was aborted.'

export default function ({ $axios, $auth, $route, redirect }) {
  $axios.onError(async (error) => {
    if ([401, 403].includes(error?.response?.status)) {
      $auth.$storage.removeUniversal(USER_SESSION_DATA_KEY)
      await $auth.logout()
      redirect(COMMON_LOGIN_ROUTE)
    } else {
      /**
       * Nuxt auth is also called this function when token is expired. No API is called.
       */
      if (error && error.message === ERROR_MESSAGE_IF_THROW_BY_NUXT_AUTH) {
        $auth.$storage.removeUniversal(USER_SESSION_DATA_KEY)
        await $auth.logout()
        const backUrl = $route?.query?.backUrl
        redirect(COMMON_LOGIN_ROUTE + backUrl)
        window.location.reload() // Required.
      }
    }
    throw error
  })

  $axios.onRequest((config) => {
    /**
     * We need to send refresh token manually because auth strategy doesn't fulfill our requirement.
     */
    const refreshToken = $auth?.strategy?.refreshToken?.get()
    if (refreshToken) {
      config.headers['refreshtoken'] = $auth?.strategy?.refreshToken?.get()
    }
  })

  $axios.onResponse((response) => {
    /**
     * If Token is expired then we got new token in res.headers['x-token'].
     */
    const token = response?.headers['x-token'] ?? false
    if (token) {
      $auth.strategy.token.set(token)
    }
    const refreshToken = response?.headers['x-refresh-token'] ?? false
    if (refreshToken) {
      $auth.strategy.refreshToken.set(refreshToken)
    }
  })
}
