//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Navbar from '@/components/Navbar.vue'
import Sidebar from '@/components/Sidebar.vue'
import {
  APPLICATION_REQUEST_TYPE_CONSUMER,
  USER_SESSION_DATA_KEY,
} from '@/constants'
import { URLS_FOR_HIDE_NAVBAR_DROPDOWN_AND_SIDEBAR } from '@/constants/navbar'
import { COMMON_LOGIN_ROUTE, PUBLIC_ROUTES } from '~/helpers/portal'
import { changeRoute } from '~/helpers/changeRoute'
import footerCopyRight from '~/components/footerCopyRight.vue'
import { mapGetters } from 'vuex'
import {
  BUSINESS_DETAILS_TAB_KEY,
  MAINTAB_REQUIRE_KEY,
} from '~/constants/applications'
import { datadogRum } from '@datadog/browser-rum'

export default {
  components: { Navbar, Sidebar, footerCopyRight },
  data() {
    return {
      URLName: '',
      PUBLIC_ROUTES,
      isCollapse: false,
      APPLICATION_REQUEST_TYPE_CONSUMER,
    }
  },
  computed: {
    ...mapGetters('applications', ['getApplication']),
    shouldRenderSidebar() {
      return (
        this.$auth.loggedIn &&
        !URLS_FOR_HIDE_NAVBAR_DROPDOWN_AND_SIDEBAR.includes(this.URLName)
      )
    },
  },
  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.$socket.io.on('connection', () => {
      console.log('Connection established!')
    })

    this.$socket?.on('userDataUpdated', async (userData) => {
      if (
        JSON.parse(localStorage.getItem('auth.user'))._id === userData.data._id
      ) {
        window.localStorage.setItem('auth.user', JSON.stringify(userData.data))
        this.$auth.setUser(userData.data)
        this.$auth.$storage.setUniversal(
          USER_SESSION_DATA_KEY,
          userData.data,
          true
        )
      }
    })

    this.$socket?.on('vrgDataUpdated', async (dataFromBackEnd) => {
      const { id, applicationId } = this.$route.params

      if (
        dataFromBackEnd.vrgId === id &&
        (applicationId === dataFromBackEnd.applicationId ||
          dataFromBackEnd.applicationId === undefined ||
          dataFromBackEnd.applicationId === '')
      ) {
        const oldApplicationType = this.getApplication.applicationType

        await this.$store.dispatch('applications/getVRGApplication', {
          id,
        })

        await this.$store.dispatch('applications/fetchApplications', {
          id,
          applicationId,
        })

        if (oldApplicationType !== this.getApplication.applicationType) {
          if (
            this.getApplication.applicationType ===
            APPLICATION_REQUEST_TYPE_CONSUMER
          ) {
            await changeRoute({
              route: this.$route,
              router: this.$router,
              path: `/finance/applications/${id}/${applicationId}`,
              query: {
                maintab: MAINTAB_REQUIRE_KEY,
              },
            })
            this.$nuxt.$emit('updateComponent', MAINTAB_REQUIRE_KEY)
          } else {
            await changeRoute({
              route: this.$route,
              router: this.$router,
              path: `/finance/applications/${id}/${applicationId}`,
              query: {
                maintab: BUSINESS_DETAILS_TAB_KEY,
              },
            })
            this.$nuxt.$emit('changeTab', {
              tab: BUSINESS_DETAILS_TAB_KEY,
              subTab: 'details',
            })
          }
        }
        this.$nuxt.$emit('set-loan-submission-editmode')
        this.$nuxt.$emit('reset-settlement-data')
        this.$nuxt.$emit('update-assets-and-liabilities-subtab')
        this.$nuxt.$emit('get-updated-status-application-link')
        if (!sessionStorage.getItem('uniquePatchRequestID')) {
          if (dataFromBackEnd?.message) {
            this.$notify({
              title: 'Information',
              message: dataFromBackEnd.message,
              type: 'info',
            })
          }
        } else {
          sessionStorage.removeItem('uniquePatchRequestID')
        }
      }
    })

    this.URLName = this.$route.name
    try {
      const isPublicRoute = PUBLIC_ROUTES.includes(this.$router.app.$route.name)
      if (isPublicRoute) {
        return
      } else if (this.$auth.loggedIn) {
        const { user } = this.$auth
        if (user && user._id) {
          datadogRum.setUser({
            id: user._id,
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            mobile: user.mobile,
            role: user.type,
          })
        } else {
          if (localStorage.getItem('auth.user')) {
            try {
              const userData = JSON.parse(localStorage.getItem('auth.user'))
              if (userData) {
                datadogRum.setUser({
                  id: userData._id,
                  name: `${userData.firstName} ${userData.lastName}`,
                  email: userData.email,
                  type: userData.type,
                  mobile: userData.mobile,
                  role: userData.type,
                })
                this.$auth.setUser(userData)
                this.$auth.$storage.setUniversal(
                  USER_SESSION_DATA_KEY,
                  userData,
                  true
                )
              }
            } catch (error) {
              this.$auth.$storage.removeUniversal(USER_SESSION_DATA_KEY)
              this.$auth.setUser(null)
              this.$auth.logout()
            }
          } else {
            this.$auth.$storage.removeUniversal(USER_SESSION_DATA_KEY)
            this.$auth.setUser(null)
            this.$auth.logout()
          }
        }
      } else {
        const currentUrl = encodeURIComponent(this.$route.fullPath)
        changeRoute({
          route: this.$route,
          router: this.$router,
          path: `${COMMON_LOGIN_ROUTE}?backUrl=${currentUrl}`,
        })
      }
    } catch (e) {
      console.error(e)
    }
    const result = localStorage.getItem('isMenuCollapse')
    this.isCollapse = result ? JSON.parse(result) : false
    this.$nuxt.$on('set-menu-collapse', (value) => {
      this.isCollapse = value
      localStorage.setItem('isMenuCollapse', value)
    })
  },
  methods: {
    async onLogout() {
      await this.$auth.logout()
      this.$auth.$storage.removeUniversal(USER_SESSION_DATA_KEY)
      this.$nuxt.$emit('set-menu-collapse', false)
      changeRoute({
        route: this.$route,
        router: this.$router,
        path: COMMON_LOGIN_ROUTE,
      })
    },
  },
}
