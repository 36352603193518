//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  ERROR_MESSAGE_FOR_TYPE,
  ERROR_MESSAGE_FOR_MAKE,
  ERROR_MESSAGE_FOR_MODEL,
  ERROR_MESSAGE_FOR_MODEL_YEAR,
  ERROR_MESSAGE_FOR_MODEL_TYPE,
  ERROR_MESSAGE_FOR_MODEL_CONDITION,
  ERROR_MESSAGE_FOR_SALE_TYPE,
  ERROR_MESSAGE_FOR_DETAILS,
  ERROR_MESSAGE_FOR_MODEL_DESCRIPTION,
  ERROR_MESSAGE_FOR_MAX_20_CHARS,
  LABEL_FOR_VEHICLE_DESCRIPTION,
  LABEL_FOR_DESCRIPTION,
} from '@/constants'
import { setVehicleYear } from '~/helpers/application'

import {
  LABEL_FOR_CANCEL_BUTTON,
  LABEL_DOCUMENT_ASSET_DETAILS,
  LABEL_DOCUMENT_TYPE,
  LABEL_DOCUMENT_MAKE,
  LABEL_DOCUMENT_YEAR,
  LABEL_DOCUMENT_MODEL_TYPE,
  LABEL_DOCUMENT_CONDITION,
  LABEL_DOCUMENT_SALE_TYPE,
  LABEL_DOCUMENT_MODEL,
  LABEL_DOCUMENT_DETAILS,
  LABEL_ASSET_TYPE_OPTION_VEHICLE,
  PLACEHOLDER_FOR_ASSET_DESCRIPTION,
  LABEL_ASSET_TYPE_OPTION_OTHER,
  LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN_KEY,
  LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN,
  ASSET_TYPE_OPTIONS,
  SALE_TYPE_OPTIONS,
  CONDITION_OPTIONS,
  LABEL_DOCUMENT_VIN,
  LABEL_DOCUMENT_REGISTRATION_PLATE,
  WEB_APP_JOURNEY,
  SALE_TYPE_OPTIONS_V4,
  LABEL_ASSET_TYPE_STILL_DECIDING,
  ASSET_SUB_TYPE_OPTIONS_FOR_LOAN,
} from '@/constants/applications'
import { handleCustomerNote } from '../mixins/customernote.mixin'
import { mapGetters } from 'vuex'
export default {
  mixins: [handleCustomerNote],
  name: 'RequestAssetDetails',
  props: {
    openDialogAssetDetailsSettlement: {
      type: Boolean,
      default: false,
    },
    sharedData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showSaleTypeErr: false,
      showConditionErr: false,
      SALE_TYPE_OPTIONS_V4,
      LABEL_FOR_CANCEL_BUTTON,
      LABEL_DOCUMENT_ASSET_DETAILS,
      ASSET_SUB_TYPE_OPTIONS_FOR_LOAN,
      LABEL_DOCUMENT_TYPE,
      LABEL_DOCUMENT_MAKE,
      LABEL_DOCUMENT_YEAR,
      LABEL_DOCUMENT_MODEL_TYPE,
      LABEL_DOCUMENT_CONDITION,
      LABEL_DOCUMENT_SALE_TYPE,
      LABEL_DOCUMENT_MODEL,
      LABEL_DOCUMENT_DETAILS,
      LABEL_ASSET_TYPE_OPTION_VEHICLE,
      LABEL_ASSET_TYPE_OPTION_OTHER,
      LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN_KEY,
      LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN,
      LABEL_DOCUMENT_VIN,
      LABEL_DOCUMENT_REGISTRATION_PLATE,
      ERROR_MESSAGE_FOR_TYPE,
      ERROR_MESSAGE_FOR_MAKE,
      ERROR_MESSAGE_FOR_MODEL,
      ERROR_MESSAGE_FOR_MODEL_YEAR,
      ERROR_MESSAGE_FOR_MODEL_TYPE,
      ERROR_MESSAGE_FOR_MODEL_CONDITION,
      ERROR_MESSAGE_FOR_SALE_TYPE,
      LABEL_FOR_VEHICLE_DESCRIPTION,
      ERROR_MESSAGE_FOR_DETAILS,
      PLACEHOLDER_FOR_ASSET_DESCRIPTION,
      ERROR_MESSAGE_FOR_MAX_20_CHARS,
      assetData: { ...this.sharedData },
      manuallySelected: false,
      modelDisabled: true,
      yearDisabled: true,
      modelTypeDisabled: true,
      MAKE: [],
      MODEL_OPTIONS: [],
      YEARS_OPTIONS: [],
      MODEL_TYPES_OPTIONS: [],
      ASSET_TYPE_OPTIONS,
      SALE_TYPE_OPTIONS,
      CONDITION_OPTIONS,
      rules: {
        assetType: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_TYPE,
            trigger: ['blur', 'change'],
          },
        ],
        makeCode: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_MAKE,
            trigger: ['blur', 'change'],
          },
        ],
        familyCode: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_MODEL,
            trigger: ['blur', 'change'],
          },
        ],
        year: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_MODEL_YEAR,
            trigger: ['blur', 'change'],
          },
        ],
        codeDescription: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_MODEL_TYPE,
            trigger: ['blur', 'change'],
          },
        ],
        condition: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_MODEL_CONDITION,
            trigger: ['blur', 'change'],
          },
        ],
        'vehicle.assetDetail': [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_DETAILS,
            trigger: ['blur', 'change'],
          },
        ],
        vIN: [
          {
            max: 20,
            message: ERROR_MESSAGE_FOR_MAX_20_CHARS,
            trigger: ['blur', 'change'],
          },
        ],
        registrationPlate: [
          {
            max: 20,
            message: ERROR_MESSAGE_FOR_MAX_20_CHARS,
            trigger: ['blur', 'change'],
          },
        ],
        customerNote: [
          {
            message: ERROR_MESSAGE_FOR_MODEL_DESCRIPTION,
            trigger: ['blur', 'change'],
          },
        ],
      },
      LABEL_FOR_DESCRIPTION,
      LABEL_ASSET_TYPE_STILL_DECIDING,
    }
  },
  mounted() {
    if (this.sharedData?.vehicle?.customerNote) {
      this.manuallySelected = true
    }
  },
  computed: {
    ...mapGetters('applications', ['getApplication']),
    assetForm() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.assetData = JSON.parse(JSON.stringify(this.sharedData))
      if (this.sharedData?.vehicle?.customerNote) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.manuallySelected = true
      }
      return { ...this.sharedData }
    },
    purchaseSource: {
      get() {
        if (
          this.assetData.vehicle.purchaseSource ===
          LABEL_ASSET_TYPE_STILL_DECIDING
        ) {
          return ''
        } else {
          return this.assetData.vehicle.purchaseSource
        }
      },
      set(val) {
        if (val) {
          this.assetData.vehicle.purchaseSource = val
        }
      },
    },
    condition: {
      get() {
        if (
          this.assetData.vehicle.condition === LABEL_ASSET_TYPE_STILL_DECIDING
        ) {
          return ''
        } else {
          return this.assetData.vehicle.condition
        }
      },
      set(val) {
        if (val) {
          this.assetData.vehicle.condition = val
        }
      },
    },
    isAssetTypeVehicleOrOther() {
      const { vehicle } = this.assetData
      return (
        vehicle &&
        [
          LABEL_ASSET_TYPE_OPTION_VEHICLE,
          LABEL_ASSET_TYPE_OPTION_OTHER,
        ].includes(vehicle.assetType)
      )
    },
    isAssetTypeVehicle() {
      const { vehicle } = this.assetData
      return vehicle && vehicle.assetType === LABEL_ASSET_TYPE_OPTION_VEHICLE
    },
    isAssetTypeOther() {
      const { vehicle } = this.assetData
      return vehicle && vehicle.assetType === LABEL_ASSET_TYPE_OPTION_OTHER
    },
    isAssetTypeUnknown() {
      const { vehicle } = this.assetData
      return (
        vehicle &&
        vehicle.assetType === LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN_KEY
      )
    },
    years() {
      let years_option = []
      const month = new Date().getMonth()
      const year =
        month >= 10 ? new Date().getFullYear() : new Date().getFullYear() - 1
      let data = setVehicleYear(year, month >= 10 ? 26 : 25)
      years_option = data
      return years_option
    },
    isWebAppJourneyV4() {
      return (
        this.getApplication.webAppJourney ===
        WEB_APP_JOURNEY.STANDARD_V4_VERIFICATION
      )
    },
  },
  watch: {
    manuallySelected(value) {
      if (value && this.assetData.vehicle) {
        this.assetData.vehicle.code = ''
        this.assetData.vehicle.makeCode = ''
        this.assetData.vehicle.makeDescription = ''
        this.assetData.vehicle.familyCode = ''
        this.assetData.vehicle.familyDescription = ''
        this.assetData.vehicle.codeDescription = ''
      } else {
        this.assetData.vehicle.customerNote = ''
      }
    },
    async openDialogAssetDetailsSettlement() {
      const response = await this.$store.dispatch(`applications/getAllMakes`)
      this.MAKE = response.data
      this.assetData = JSON.parse(JSON.stringify(this.sharedData))
      return { ...this.sharedData }
    },
  },
  created() {
    if (
      this.assetData?.vehicle &&
      this.assetData?.vehicle?.assetType !==
        this.LABEL_ASSET_TYPE_OPTION_VEHICLE
    ) {
      if (
        this.assetData.appData &&
        this.assetData.appData.vehicle &&
        !this.assetData.appData.vehicle.year &&
        !this.assetData.appData.vehicle.makeDescription &&
        !this.assetData.appData.vehicle.familyDescription &&
        !this.assetData.appData.vehicle.codeDescription &&
        this.assetData.appData.vehicle.customerNote
      ) {
        this.manuallySelected = false
      } else {
        this.manuallySelected = true
      }
    }
    if (this.assetData?.vehicle?.customerNote) {
      this.manuallySelected = true
    }
  },
  methods: {
    onChangeAssetType(newAssetType) {
      if (newAssetType === 'Vehicle') {
        this.assetData.assetType = newAssetType
      }
    },
    async setModels() {
      this.modelDisabled = false
      this.yearDisabled = true
      this.modelTypeDisabled = true

      this.assetData.vehicle.familyCode = ''
      this.assetData.vehicle.year = ''
      this.assetData.vehicle.codeDescription = ''
      const response = await this.$store.dispatch(`applications/getAllModels`, {
        makeCode: this.assetData.vehicle.makeCode,
      })
      this.MODEL_OPTIONS = response.data
    },
    async setYears() {
      this.yearDisabled = false
      this.modelTypeDisabled = true
      this.assetData.vehicle.year = ''
      this.assetData.vehicle.codeDescription = ''

      const response = await this.$store.dispatch(`applications/getAllYears`, {
        makeCode: this.assetData.vehicle.makeCode,
        familyCode: this.assetData.vehicle.familyCode,
      })
      this.YEARS_OPTIONS = response.data
    },
    async setModelTypes() {
      this.modelTypeDisabled = false
      this.assetData.vehicle.codeDescription = ''

      const response = await this.$store.dispatch(
        `applications/getAllModelTypes`,
        {
          makeCode: this.assetData.vehicle.makeCode,
          familyCode: this.assetData.vehicle.familyCode,
          year: this.assetData.vehicle.year,
        }
      )
      this.MODEL_TYPES_OPTIONS = response.data
    },
    async submitForm() {
      this.showSaleTypeErr = true
      this.showConditionErr = true
      //   const { id, applicationId } = this.$route.params
      let isValid = false
      this.$refs.assetData.validate((result) => {
        // eslint-disable-next-line no-undef
        return (isValid = result)
      })
      if (!isValid || !this.purchaseSource || !this.condition) {
        return
      }
      if (
        this.assetData.vehicle.condition === '' ||
        this.assetData.vehicle.condition === LABEL_ASSET_TYPE_STILL_DECIDING
      ) {
        return
      }

      if (
        this.assetData.vehicle.purchaseSource === '' ||
        this.assetData.vehicle.purchaseSource ===
          LABEL_ASSET_TYPE_STILL_DECIDING
      ) {
        return
      }
      if (
        this.assetData?.vehicle &&
        this.assetData?.vehicle?.assetType ===
          this.LABEL_ASSET_TYPE_OPTION_VEHICLE
      ) {
        if (this.MAKE.length > 0) {
          const getMakeDescription = this.MAKE.filter(
            (checkMakeCode) =>
              checkMakeCode.makeCode === this.assetData.vehicle.makeCode
          )

          if (getMakeDescription.length > 0) {
            this.assetData.vehicle.makeDescription =
              getMakeDescription[0].description
          }
        }

        if (this.MODEL_OPTIONS.length > 0) {
          const getFamilyDescription = this.MODEL_OPTIONS.filter(
            (checkFamilyCode) =>
              checkFamilyCode.familyCode === this.assetData.vehicle.familyCode
          )

          if (getFamilyDescription.length > 0) {
            this.assetData.vehicle.familyDescription =
              getFamilyDescription[0].description
          }
        }

        if (this.MODEL_TYPES_OPTIONS.length > 0) {
          const getModelTypeDescription = this.MODEL_TYPES_OPTIONS.filter(
            (checkModelType) =>
              checkModelType.code === this.assetData.vehicle.codeDescription
          )

          if (getModelTypeDescription.length > 0) {
            this.assetData.vehicle.code = getModelTypeDescription[0].code
            this.assetData.vehicle.codeDescription =
              getModelTypeDescription[0].description
          }
        }
      }

      const { id, applicationId } = this.$route.params
      await this.$store.dispatch('applications/updateRequirementAssetDetails', {
        assetData: this.assetData,
        id,
        applicationId,
      })

      this.closeDialog()
    },
    closeDialog() {
      this.$nuxt.$emit('closeAssetDetailsDialogSettlement')
    },
  },
}
