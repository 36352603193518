import { render, staticRenderFns } from "./Documents.vue?vue&type=template&id=1a6f87d7&scoped=true&"
import script from "./Documents.vue?vue&type=script&lang=js&"
export * from "./Documents.vue?vue&type=script&lang=js&"
import style0 from "./Documents.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./Documents.vue?vue&type=style&index=1&id=1a6f87d7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a6f87d7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DocumentUpload: require('/opt/build/repo/components/DocumentUpload.vue').default,BankStatementUpload: require('/opt/build/repo/components/BankStatementUpload.vue').default,DocumentDialog: require('/opt/build/repo/components/DocumentDialog.vue').default})
